<template>
<!-- <ion-tab tab="home"> -->
  <ion-page>
    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>{{ tabName }}</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <ion-content :fullscreen="true">

      <HomeContainer name="Tab 1 page" />

    </ion-content>
  </ion-page>
<!-- </ion-tab> -->
</template>

<script lang="ts">
import { IonPage, IonContent, } from "@ionic/vue";
import HomeContainer from "@/components/HomeContainer.vue";

export default {
  name: "Home",
  components: {
    HomeContainer,
    IonContent,
    IonPage,
  },
};
</script>