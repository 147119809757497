
import { IonPage, IonContent, } from "@ionic/vue";
import HomeContainer from "@/components/HomeContainer.vue";

export default {
  name: "Home",
  components: {
    HomeContainer,
    IonContent,
    IonPage,
  },
};
